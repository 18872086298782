import cookies from "../cookies";
import {atob, btoa} from "./base64Helper";

export function parseCookies(cookieName, defaultValue = {}){
    let encoded = cookies.getCookieValue(cookieName);
    return encoded ? JSON.parse(atob(encoded)) : defaultValue;
}

export function setCookie(cookieName, value) {
    if(typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    cookies.setCookie(cookieName, btoa(value));
}
