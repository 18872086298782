import React, {useEffect} from "react";
import {parseCookies, setCookie} from "../base/cookieHelper";
import AdBannersApi from "../api/adBanners";

const AdsImpressionsTracker = () => {

    useEffect(() => {
        document.querySelectorAll('.ad-track-wrapper').forEach((element) => {
            detectElementVisibility(element);
            initTrackClick(element);
        });
    }, []);

    const detectElementVisibility = (element) => {
        const bannerId = element.getAttribute('data-ad-id');

        const observer = new IntersectionObserver(([entry]) => {
            if(entry.isIntersecting === true){
                trackImpression(bannerId);
                observer.disconnect();
            }

        });
        observer.observe(element);
    };

    const initTrackClick = (element) => {
        const bannerId = element.getAttribute('data-ad-id');
        element.addEventListener('click', (e) => trackClick(e, bannerId), false);
    };

    const trackClick = (e, bannerId) => {
        let clickedBanners = parseCookies('ca_ads_c', []);
        if (clickedBanners.indexOf(bannerId) < 0 && e.target.matches('a, a *')) {
            clickedBanners.push(bannerId);
            setCookie('ca_ads_c', clickedBanners);
            AdBannersApi.trackClick(bannerId)
        }
    };

    const trackImpression = (bannerId) => {
        let showedBanners = parseCookies('ca_ads', []);
        if (showedBanners.indexOf(bannerId) < 0){
            showedBanners.push(bannerId);
            setCookie('ca_ads', showedBanners);
            AdBannersApi.trackImpression(bannerId);
        }
    };

    return null;
};
export default AdsImpressionsTracker;