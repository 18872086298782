import React, { Component } from 'react';
import { Provider } from 'react-redux';
import AdRender from './render';
import store from '../store/store';


export default class Ad extends Component {

    render() {
        return (
            <Provider store={store}>
                <AdRender {...this.props} />
            </Provider>
        )
    }
}