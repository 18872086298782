import React, {Component, Fragment} from "react";

class DropDownButton extends Component {

    constructor(props) {
        super(props);
        this.state = {filterOpened: false};
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.collapse();
        }
    };

    expand = (e) => {
        this.setState({filterOpened: !this.state.filterOpened});
    };

    collapse = () => {
        this.setState({filterOpened: false});
    };

    onChange = (filter) => {
        this.props.onChange(filter);
        this.collapse();
    };

    render() {
        let _this = this;
        return(
            <div className='filter-container' ref={this.wrapperRef}>
                <button className={`filter ${this.props.btnClassName ? this.props.btnClassName : 'button-grey'} ${this.props.value ? ' secondary-link' : ''}`} onClick={this.expand}>
                    {this.props.value ?
                        <Fragment> {this.props.value[_this.props.optionsLabel]}{this.props.icon === 'caret-down' ? <span className={"small-icon " + this.props.icon}/> : null} </Fragment> :
                        <Fragment>{this.props.name}<span className={"small-icon " + this.props.icon}/></Fragment> }
                </button>
                {this.state.filterOpened ?
                    <div className="filter-content active">
                        <ul>
                            {this.props.options.map(function (filter, i) {
                                return (<li key={`filter${i}`}>
                                    <a onClick={() => _this.onChange(filter)}>{filter[_this.props.optionsLabel]}</a>
                                </li>)
                            })}
                        </ul>
                    </div>
                    : null }
            </div>
        )
    }

}

export default DropDownButton;