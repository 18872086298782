import React, {Fragment} from "react";

export const ArticleCardImage = ({article, withSeo, lazyLoading, useLargeImage}) => {

    let imageProps = {alt: article.name};
    imageProps['src'] = useLargeImage ? article.large_image_path : article.image_path;
    if (lazyLoading) {
        imageProps['data-src'] = imageProps['src'];
        imageProps['src'] = '/images/pixel_9_5.gif';
    }

    const seoImgProps = withSeo ? {itemType: 'http://schema.org/ImageObject', itemScope: true, itemProp: 'image'} : {};

    const renderArticleImageMetaSEO = () => {
        if (!withSeo)
            return null;
        return <Fragment>
            <meta itemProp="url" content={article.large_image_path} />
            <meta itemProp="width" content="700" />
            <meta itemProp="height" content="400" />
        </Fragment>;
    };

    return (
        <div className="card__image" {...seoImgProps} >
            {renderArticleImageMetaSEO()}
            <a href={article.path}>
                <img {...imageProps} />
                {article.video_url ? <div className="layer-img"><div className="small-icon play-button"/></div> : null }
            </a>
        </div>
    )

};