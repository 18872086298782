import {actions} from 'react-fields'

class PopupsApi {

    static getPopups(path) {
        let url = `/api/popups?path=${encodeURIComponent(path)}`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            })
    }

    static setViewPopup(id, path) {
        let url = `/api/popups/${id}?path=${encodeURIComponent(path)}`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            })
    }

    static trackClick(id) {
        const url = `/api/popups/${id}/click`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            }).catch(error => {
            console.log(error);
        })
    }

}

export default PopupsApi;