import React, {Component} from "react";
import Slider from "react-slick";

function ArrowRight() {
    return (
        <svg x="0px" y="0px" viewBox="0 0 20 20" width="20px" height="20px">
            <g>
                <path className="st0" d="M9.56,1.75c0.37-0.37,0.96-0.37,1.32,0l7.59,7.59c0.37,0.37,0.37,0.96,0,1.32l-7.59,7.59
                    c-0.37,0.37-0.96,0.37-1.32,0l-0.87-0.87c-0.37-0.37-0.36-0.98,0.02-1.34l4.71-4.48H2.19c-0.52,0-0.94-0.42-0.94-0.94V9.37
                    c0-0.52,0.42-0.94,0.94-0.94h11.23L8.71,3.95C8.32,3.59,8.32,2.98,8.69,2.61L9.56,1.75z"/>
            </g>
        </svg>
    )
}

function ArrowLeft() {
    return (
        <svg x="0px" y="0px" viewBox="0 0 20 20" width="20px" height="20px">
            <g>
                <path className="st0" d="M11.31,2.61c0.38,0.37,0.37,0.98-0.02,1.34L6.59,8.44h11.23c0.52,0,0.94,0.42,0.94,0.94v1.25
                    c0,0.52-0.42,0.94-0.94,0.94H6.59l4.71,4.48c0.38,0.36,0.39,0.97,0.02,1.34l-0.87,0.87c-0.36,0.37-0.96,0.37-1.32,0l-7.59-7.59
                    c-0.37-0.36-0.37-0.96,0-1.32l7.59-7.59c0.36-0.37,0.96-0.37,1.32,0L11.31,2.61z"/>
            </g>
        </svg>
    )
}

function SliderArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} onClick={onClick} >
            {children}
        </div>
    );
}

const DEFAULT_SLIDER_SETTINGS = {
    dots: true,
    dotsClass: 'slider-bar',
    infinite: false,
    arrows: true,
    speed: 600,
    cssEase:  'ease-out',
    variableWidth: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: 'slick-slider--with-btns team__slider',
    nextArrow: <SliderArrow><ArrowRight /></SliderArrow>,
    prevArrow: <SliderArrow><ArrowLeft /></SliderArrow>,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 1165,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 950,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                variableWidth: true,
                dots: true,
            }
        }
    ]
};

export default class TeamCarousel extends Component {

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS
    };

    renderItem = (item, index) => {
        return (<div key={`${this.state.keyPrefix}${index}`} className={'fnt-light' + (this.state.active === index ? ' fade-in' : '')} onClick={() => this.handleClick(index)}>
            <div className={'team__img'} style={{background: `url(${item.image.original})`}}>
                <div className="blk--on-hover">
                    <hr className="tabulation bg-secondary" />
                    <p>{item.description}</p>
                    {!!item.url ? <a href={item.url} className="clr-secondary fnt-bold no-underline">View Profile</a> : null}
                </div>
            </div>

            <p className="h4 mar-bot-10">{item.name}</p>
            <p className="clr-grey-4d">{item.title}</p>
        </div>)
    };


    render() {
        return(
            <div className={'grid-container grid-container--no-r-pad---large-down'}>
                <Slider {...this.state.settings}>
                    {this.props.collection.map((item, index) => this.renderItem(item, index))}
                </Slider>
            </div>
        )
    }

}