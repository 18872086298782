import React from "react";

export const ArticleCardInfo = props => {

    return (
        <div className="grid-x clr-grey-a5 fnt-16">
            <time dateTime={props.date} className={'cell auto fnt-light'}>{props.date_formatted || props.date} </time>
            <div className={'cell shrink'}><div className="icon icon--comments"/></div>
            <div className={'cell shrink'}>{props.comments_count || 0}</div>
        </div>
    )

};