import React, {Fragment} from 'react';
import {actions, TextField, InputTypeField} from "react-fields";
import ImgDesktop from "../../../../public/images/newsletter/popup/img-desktop.png";
import ImgTablet from "../../../../public/images/newsletter/popup/img-tablet.png";
import ImgMobile from "../../../../public/images/newsletter/popup/img-mobile.png";
import Logo from "../../../../public/images/hr-logo-color.svg";
import LogoOne from "../../../../public/images/other-logos/business-insider-color.webp";
import LogoTwo from "../../../../public/images/other-logos/independent-color.webp";
import LogoThree from "../../../../public/images/other-logos/yahoo-finance-color.webp";
import SubscriptionActionHOC from "./subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    return (
        <div className={`subscribe fade-in`}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Enter email" className={"input-wrapper input-wrapper--center"} />
            <button className={'width-100p button--secondary height-50 fnt-18 mar-top-20 text-center'} onClick={props.submitForm}>
                SIGN UP FOR FREE <span className="icon icon--arrow-right--big-white mar-l-10"/>
            </button>
        </div>
    )
});

export default function FormModal(props) {

    return (
        <Fragment>
            <div className="flex-container flex-dir-column-reverse large-flex-dir-row align-middle">
                <div className="flex-child-auto reveal-promo__cntnt text-center">
                    <img className={'reveal-promo__logo'} src={Logo} width="137" height="42"  alt="HRAddict Logo" />
                    <div className="reveal-promo__title">Weekly wisdom<br />for HR pros.</div>
                    <div className="reveal-promo__text">
                        Sign up to <a href={'http://newsletter.hraddict.com/'}><b>HR</b><b className={'clr-secondary'}>Addict's</b></a> weekly newsletter for HR hacks, news, latest trends, and resources <b>— for FREE!</b>
                    </div>
                    <div>
                        <SubscriptionForm formType={props.formType} />
                    </div>
                    <div className={'fnt-13 clr-grey-8e mar-top-5 mar-bot-20'}>Unsubscribe anytime</div>
                    <div className={'fnt-15 clr-grey-8e'}>Featured on</div>
                    <div className={'grid-x grid-padding-x align-center'}>
                        <div className={'cell shrink mar-top-15'}><img src={LogoOne} width="137" height="42"  alt="Business Insider" className={'grayscale img-mh-20'} /></div>
                        <div className={'cell shrink mar-top-15'}><img src={LogoThree} width="137" height="42"  alt="Yahoo Finance" className={'grayscale img-mh-20'} /></div>
                        <div className={'cell shrink mar-top-15'}><img src={LogoTwo} width="137" height="42"  alt="Independent" className={'grayscale img-mh-20'} /></div>
                    </div>
                </div>
                <div className="flex-child-shrink reveal-promo__img-cntr">
                    <img src={ImgDesktop} width="483" height="507"  alt="Newsletter Image" className={'show-for-large'} />
                    <img src={ImgTablet} width="606" height="200"  alt="Newsletter Image" className={'show-for-medium-only width-100p'} />
                    <img src={ImgMobile} width="375" height="200"  alt="Newsletter Image" className={'show-for-small-only width-100p'} />
                </div>
            </div>
        </Fragment>
    )
}