import React, {useEffect, useState} from 'react';
import {actions} from "react-fields";

const BTNS_TO_SHOW = 3;

export default function Pagination(props) {

    const buttonClass = props.buttonClass || "";

    const getPages = () => {
        let pages = [];
        if (props.totalPages <= 1){
            return [];
        }

        let firstPage = props.page -1;
        let lastPage = props.page + 1;
        if (props.page <= (BTNS_TO_SHOW-1)) {
            firstPage = 1;
            lastPage = BTNS_TO_SHOW;
        }
        if (props.page === props.totalPages) {
            lastPage = props.totalPages;
            firstPage = props.totalPages - BTNS_TO_SHOW + 1;
        }
        if (props.totalPages <= BTNS_TO_SHOW) {
            firstPage = 1;
            lastPage = props.totalPages;
        }

        for (let i = firstPage; i <= lastPage; i++){
            pages.push(i);
        }
        return pages;
    };

    let [pages, setPages] = useState(getPages());

    useEffect(
        () => {
            setPages(getPages());
        }, [props.page, props.totalPages]
    );

    if (pages.length === 0){
        return null;
    }

    const getHref = (page) => {
        let options = {...props.options};
        delete options['page'];
        if (page > 1) {options['page'] = page}
        const path = props.path || `${location.origin}${location.pathname}`;
        const search = actions.paramsToURI(options);
        return search.length > 0 ? `${path}?${search}` : path;
    };

    let firstOptions = props.page === 1 ? {className: 'disabled'} : {rel: 'prev', onClick: (e) => props.goToPage(e,1), href: getHref(1)};
    let prevOptions = props.page === 1 ? {className: 'disabled'} : {rel: 'prev', onClick: (e) => props.goToPage(e, props.page -1), href: getHref(props.page -1)};
    let nextOptions = props.page >= props.totalPages ? {className: 'disabled'} : {rel: 'next', onClick: (e) => props.goToPage(e, props.page + 1 ), href: getHref(props.page +1)};
    let lastOptions = props.page >= props.totalPages ? {className: 'disabled'} : {rel: 'next', onClick: (e) => props.goToPage(e, props.totalPages ), href: getHref(props.totalPages)};
    if(props.disablePaginationLinks){
        delete prevOptions.href;
        delete nextOptions.href;
    }
    return (
        <nav aria-label="Pagination">
            <ul className="flex-list pagination text-center">
                <li className="pagination-previous pagination-first">
                    {props.disablePaginationLinks ? <button {...firstOptions}><span className="show-for-sr">First page</span></button> : <a {...firstOptions}  ><span className="show-for-sr">First page</span></a> }
                </li>
                <li className="pagination-previous">
                    {props.disablePaginationLinks ? <button {...prevOptions}><span className="show-for-sr">Previous page</span></button> : <a {...prevOptions}  ><span className="show-for-sr">Previous page</span></a> }
                </li>
                {' '}
                {pages.map((page) => {
                    if (props.page === page) {
                        return <React.Fragment key={page}>
                            <li className={`current ${buttonClass}`}><span className="show-for-sr">You're on page</span> {page}</li>
                            {' '}
                        </React.Fragment>;
                    }
                    let options = props.disablePaginationLinks ? {onClick: (e) => props.goToPage(e, page)} : {href: getHref(page), onClick: (e) => props.goToPage(e, page)};
                    if (props.page-1 === page) {options['rel'] = 'prev';}
                    if (props.page+1 === page) {options['rel'] = 'next';}
                    return <React.Fragment key={page}>
                        <li className={props.page === page ? `current ${buttonClass}` : ""}>
                            {props.disablePaginationLinks ? <button {...options}><span className="show-for-sr">Page </span>{page}</button> : <a {...options}><span className="show-for-sr">Page </span>{page}</a> }
                        </li>
                        {' '}
                    </React.Fragment>
                })}
                <li className="pagination-next">
                    {props.disablePaginationLinks ? <button {...nextOptions}><span className="show-for-sr">Next page</span></button> : <a {...nextOptions}><span className="show-for-sr">Next page</span></a> }
                </li>
                <li className="pagination-next pagination-last">
                    {props.disablePaginationLinks ? <button {...lastOptions}><span className="show-for-sr">Last page</span></button> : <a {...lastOptions}><span className="show-for-sr">Last page</span></a> }
                </li>
            </ul>
        </nav>
    )

}