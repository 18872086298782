import {actions} from 'react-fields'
import store from '../store/store';
import {loadedBanners} from "../actions/adBannersActions";
import 'whatwg-fetch';

class AdBannersApi {

    static getList(params) {
        let paramsStr = Object.keys(params).map(function(k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
        }).join('&');
        const url = `/api/ad_banners/load?${paramsStr}`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then(  (response) => {
                store.dispatch(loadedBanners(response));
        }).catch(error => {
            console.log(error);
        })
    }

    static trackImpression(bannerId) {
        const url = `/api/ad_banners/${bannerId}/impression`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            }).catch(error => {
            console.log(error);
        })
    }

    static trackClick(bannerId) {
        const url = `/api/ad_banners/${bannerId}/click`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            }).catch(error => {
            console.log(error);
        })
    }

}

export default AdBannersApi;