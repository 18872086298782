import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "./subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    let divClasses = 'subscribe fade-in';
    let btnText = props.formAttrs.btnText || 'SIGN UP FOR FREE';

    return (
        <div className={divClasses}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Enter email" className={"input-wrapper input-wrapper--center"} />
            <button className={'width-100p button--secondary height-50 fnt-18 mar-top-20 text-center'} onClick={props.submitForm}>
                {btnText} <span className="icon icon--arrow-right--big-white mar-l-10"/>
            </button>
        </div>
    )
});

export default function TwoLineInputSubscription(props) {
    return <SubscriptionForm {...props} />
}
