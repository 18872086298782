import React, { Component } from 'react';
import {SelectField, actions} from 'react-fields';

class CollectionForSelect extends React.Component {

    state = {
            collection: []
        };



    componentDidMount(){
      this.getCollection();
    }

    getCollection(){
        let _this = this;
        return fetch(actions.getData(this.props.url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({collection: data});
            });

    }

    render() {
        let props = {...Object.assign({},this.props,{options: this.state.collection.length > 0 ? this.state.collection : []})};
        return (
                <SelectField {...props} />
        )
    }
}
export default CollectionForSelect;