import React from "react";

class NoResults extends React.Component {


    render(){
        return(
            <div className="no-search-results flex-center">
                <div className="no-results-wrapper">
                    <div className="page-icon no-results-icon"/>
                    <h3>{this.props.text || "Sorry, we couldn't find anything. Try searching something else."}</h3>
                </div>
            </div>
        )
    }

} export default NoResults;