import { SET_INITIAL_AD_BANNERS, AD_BANNERS_LOADED} from '../actions/actionTypes';
import initialState from './initialState';

export default function adBanners(state = initialState.adBanners, action) {
    switch (action.type) {
        case SET_INITIAL_AD_BANNERS:
            return {collection: [], isLoaded: false};
        case AD_BANNERS_LOADED:
            return {collection: action.banners, isLoaded: true};
        default:
            return state;
    }
}