import React, {Component, Fragment} from "react";
import {ArticleCardInfo} from "./card/info";
import {ArticleCardImage} from "./card/image";

class ArticleCard extends Component {

    componentDidMount() {
        if (this.props.lazyLoading) {
            this.enableLazyLoading();
        }
    }

    enableLazyLoading = () => {
        if (window.lazyLoadInstance){
            window.lazyLoadInstance.update();
        }
        else{
            let _this = this;
            window.setTimeout(_this.enableLazyLoading,100);
        }
    };

    renderCAinfo = () => {
        return <Fragment>
            <meta itemProp="name" content="HR Addict" />
            <meta itemProp="url" content="https://hraddict.com/"/>
            <div itemScope itemType="http://schema.org/ImageObject" itemProp="logo">
                <meta itemProp="url" content="/images/hr-logo-color.svg" />
                <meta itemProp="width" content="180" />
                <meta itemProp="height" content="23" />
            </div>
        </Fragment>
    };

    renderArticleMetaSEO = () => {
        if (!this.props.withSeo)
            return null;
        let article = this.props.article;
        return <Fragment>
            <meta itemProp="headline" content={article.name} />
            <meta itemProp="articleSection" content={article.category_name} />
            <meta itemProp="datePublished" content={article.publish_date} />
            {!!article.modified_date ? <meta itemProp="dateModified" content={article.modified_date} /> : null}
            {!!article.author_name ? <div itemProp="author" itemScope itemType="http://schema.org/Person">
                <meta itemProp="name" content={article.author_name} />
                <meta itemProp="url" content={article.author_url || article.path} />
            </div> : <div itemProp="author" itemScope itemType="http://schema.org/Organization">
                {this.renderCAinfo()}
            </div>}
            <meta itemProp="mainEntityOfPage" content={""} itemScope itemType="http://schema.org/WebPage" itemID={article.path} />
            <div itemScope itemType="http://schema.org/Organization" itemProp="publisher">
                {this.renderCAinfo()}
            </div>
        </Fragment>;
    };

    render() {
        const MainTag = this.props.wrapInDiv ? `div` : `article`;
        let mainTagClass = 'card';
        if (this.props.className) { mainTagClass += ` ${this.props.className}` }
        let article = this.props.article;
        let seoProps = this.props.withSeo ? {itemType: 'http://schema.org/Article', itemScope: true, itemProp: 'hasPart'} : {};
        return (
            <MainTag {...seoProps} className={mainTagClass}>
                {this.renderArticleMetaSEO()}

                <ArticleCardImage article={article} withSeo={this.props.withSeo} lazyLoading={this.props.lazyLoading} useLargeImage={this.props.useLargeImage} />

                <div className={'relative'}><a href={article.category_link} className='category'>{article.category_name}</a></div>

                <a href={article.path} className={'details'} itemProp="url">
                    <h4 className='title'>{article.name}</h4>
                    {this.props.hideDateAndComments ? null :
                        <ArticleCardInfo comments_count={article.comments_count} date={article.publish_date} date_formatted={article.publish_date_formatted} />
                    }
                </a>
            </MainTag>
        )
    }

}
export default ArticleCard;