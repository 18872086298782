import React from "react";
import LazyLoad from 'react-lazyload';
import Index from './related';

class RelatedLazy extends React.Component {

    render() {
        return (
            <LazyLoad>
                <Index {...this.props} />
            </LazyLoad>
        )
    }

}
export default RelatedLazy;