import React, {Fragment} from "react";

export const TeamBlock = ({team_member}) => {
    return (
        <div className={'team__blk'}>
            <div className={'team__img'} style={{background: `url(${team_member.image.original})`}}>
                <div className="blk--on-hover">
                    <hr className="tabulation bg-secondary" />
                    <p>{team_member.description}</p>
                    {!!team_member.url ? <a href={team_member.url} className="clr-secondary fnt-bold no-underline">View Profile</a> : null}
                </div>
            </div>

            <p className="h4 mar-bot-10">{team_member.name}</p>
            <p className="clr-grey-4d">{team_member.title}</p>
        </div>
    )
};