import React from "react";

class Field extends React.Component {

    constructor(props) {
        super(props);
        this.state = {q: props.q || ''};
        this.searchInput = React.createRef();
    }

    setFocus = () => {
        setTimeout(() =>{
            this.searchInput.current.focus();
        }, 500)

    };


    handleKeyWordChange = (e) => {
        let value = e.target.value;
        this.setState({q: value})
    };

    onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            this.submitSearch()
        }
    };

    submitSearch = () => {
        let q = this.state.q ? `?q=${encodeURIComponent(this.state.q)}` : '';
       document.location.href = `/search${q}`

    };


    render(){
        // if (this.props.forOverlayMenu) {
        //     return (
        //         <div className="input-with-button input-with-button--icon">
        //             <input name="q"
        //                    autoComplete="off"
        //                    onChange={this.handleKeyWordChange}
        //                    placeholder="Search"
        //                    value={this.state.q}
        //                    onKeyPress={this.onPressEnter} />
        //             {/*<button onClick={this.submitSearch} className={(this.state.q && this.state.q.length > 0) ? 'active' : ''}>*/}
        //                 {/*<span className="small-icon small-magnify"/>*/}
        //             {/*</button>*/}
        //             <button onClick={this.submitSearch}><span className="icon icon--search--white"/></button>
        //         </div>
        //     )
        // }
        let wrapperClass = 'input-with-button input-with-button--icon';
        wrapperClass += this.props.forOverlayMenu ? ' input-with-button--40' : ' input-with-button--transparent input-with-button--big';
        // if (!this.props.forOverlayMenu) { wrapperClass += ' input-with-button--transparent input-with-button--big';  }
        return(
            <React.Fragment>
                <div className={wrapperClass}>
                    <input     name="q" id="searchInput" autoComplete="off" ref={this.searchInput}
                               value={this.state.q} placeholder="Search"
                               onChange={this.handleKeyWordChange} onKeyPress={this.onPressEnter} />
                    <button onClick={this.submitSearch}><span className="icon icon--search--white"/></button>
                </div>
            </React.Fragment>
        )
  }
}
export default Field;