import React, {Fragment} from "react";

export const ArticleCardSmall = ({article}) => {

    return (
        <div className="grid-x grid-margin-x grid-margin-y mar-bot-20 align-middle">
            <div className={'cell medium-shrink text-center'}>
                <a href={article.path}>
                    <img alt={article.name} src={article.image_small_url} loading={'lazy'} className={'article__sidebar-img'} />
                </a>
            </div>
            <div className={'cell medium-auto'}>
                <a href={article.path} className={'article__sidebar-lnk'}>{article.name}</a>
            </div>
        </div>
    )

};