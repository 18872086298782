
import * as types from './actionTypes';


export function setInitialBanners() {
    return {
        type: types.SET_INITIAL_AD_BANNERS

    };
}


export function loadedBanners(banners) {
    return {
        type: types.AD_BANNERS_LOADED,
        banners: banners

    };
}



