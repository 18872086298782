import React, { Component } from 'react';
import AdBannersApi from '../api/adBanners';

export default class AdsLoader extends Component {


    componentDidMount() {
        AdBannersApi.getList(this.props.params)
    }

    render() {
        return false;
    }
}