import React from "react";
import {actions} from "react-fields";
import ArticleCard from "../../article/card";

class SmallArticleList extends React.Component {

    state = { articles: [], isLoaded: false };

    componentDidMount() {
        this.getRelated();
    }

    getRelated = () => {
        let _this = this;
        let url = `/api/articles/editors_pick`;
        if (!this.props.editorsPick) {
            const paramsStr = actions.paramsToURI(this.props.params);
            url = `/api/articles/list?${paramsStr}`
        }
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({articles: (data.articles || data), isLoaded: true});
            });
    };

    render(){
        if (!this.state.isLoaded) { return null; }
        return(
            <div className={'grid-x grid-margin-x grid-margin-y'}>
                {this.state.articles.map((article, i) => {
                    return (<div key={`article_${i}`} className={'cell medium-6 large-3'}>
                        <ArticleCard article={article} className={this.props.editorsPick ? 'card--grey' : null} />
                    </div>)
                })}
            </div>
        )
    }

}

export default SmallArticleList;