import React, {useEffect, useRef} from "react";
import PopUp from '../modals/popup';
import ModalForm from './formModal';

export default function SubscribeButtonHandler(props) {

    const popup = useRef(null);

    const openModal = () => {
        popup.current.open();
    };

    useEffect(
        () => {
            if (!!props.listenToId) {
                const listenToElement = document.querySelector(props.listenToId);
                if (listenToElement) { listenToElement.addEventListener('click', openModal); }
            }
            return () => {
                if (!!props.listenToId) {
                    const listenToElement = document.querySelector(props.listenToId);
                    if (listenToElement) { listenToElement.removeEventListener('click', openModal); }
                }
            };
        },[]
    );

    const renderHandler = () => {
        switch (props.design) {
            case 'sideNavigationMenu':
                return <a onClick={(e) => {e.preventDefault(); openModal()}}>SIGN UP <span className="icon icon--envelope-small--white mar-l-10"/></a>;
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {renderHandler()}
            <PopUp ref={popup} className={'reveal-promo'} closeIcon={'blue_fill'} disableOuterPageScroll={true}>
                <ModalForm formType={props.formType} />
            </PopUp>
        </React.Fragment>
    )

}
