import React, {Fragment} from 'react';
import CALogo from "../../../../../public/images/hr-logo-color.svg";

export default function ReferralContent(props) {

    return (<Fragment>
        <div className={'text-center relative-center pad-hor-15'}>
            <img src={CALogo} alt={'CareerAddict'} className={'mar-bot-20'}/>
            <h1 className="text-center">Continue to download your gift</h1>
            <div className="grid-x grid-padding-x">
                <div className="small-12 cell text-center">
                    <div>
                        <p className="lastText">A new window in your browser will open taking you to a page where you can download your free {props.giftName}.</p>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <button onClick={props.onClick} className="button-orange">Continue to download <span className={'small-icon arrow-white-right-small'}/></button>
            </div>
        </div>
        <div className={"close-circle close-referral"} onClick={props.close}>
            <span className="top"/>
            <span className="bottom"/>
        </div>
    </Fragment>)

}