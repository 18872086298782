import React, {Component, Fragment} from "react";
import {actions} from "react-fields";
import {TeamBlock} from "./block";

export default class TeamTabs extends Component {

    state = {
        isLoaded: false,
        currentTab: 'team',
        collection: {}, chunks: []
    };

    componentDidMount() {
        let _this = this;
        fetch(actions.getData('/api/team_members'))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            let array = [...response['team']];
            let chunks = [];
            const chunkSize = 4;
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            if (chunks[chunks.length-1].length === 2 && chunks.length >= 1) {
                chunks[chunks.length-1].push(chunks[chunks.length-2].pop());
            }
            _this.setState({collection: response, chunks: chunks, isLoaded: true});
        }).catch(error => {
            console.log(error);
        })
    }

    renderChunk = (chunk, index) => {
        const largeColumnSize = 12 / chunk.length;
        return chunk.map((item, i) => {
            return (<div key={`team_${index}_${i}`} className={`cell medium-6 large-${largeColumnSize}`}>
                <TeamBlock team_member={item} />
            </div>)
        });
    };

    render() {
        if (!this.state.isLoaded) {
            return null;
        }

        return(
            <Fragment>
                <div className={'grid-container'}>
                    <div className={'grid-x grid-margin-x grid-margin-y align-center'}>
                        {this.state.chunks.map((item, index) => this.renderChunk(item, index))}
                    </div>
                </div>
            </Fragment>
        )
    }

}