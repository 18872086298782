import React from "react";
import {actions} from "react-fields";
import {ArticleCardSmall} from "../../article/cardSmall";

class Index extends React.Component {

    state = { articles: [], isloaded: false };

    componentDidMount() {
        this.getRelated();
    }

    getRelated = () => {
        let _this = this;
        let url = `/api/articles/${this.props.article_id}/related_with_options`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({articles: data, isloaded: true});
            });
    };

    render(){
        if (!this.state.isloaded) { return null; }
        return(
            <React.Fragment>
                <h3 className="article__sidebar-blk-title">Related Articles</h3>
                {this.state.articles.map((article, index) => {
                    return (<ArticleCardSmall key={`relatedCard${index}`} article={article} />)
                })}
            </React.Fragment>
        )
    }

}

export default Index;