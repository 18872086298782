import React from 'react';
import PopUp from '../modals/popup';
import SearchBox from '../search/field';

class SearchOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.searchOpenBtn = document.querySelector('#searchOpenBtn');
        this.searchPopUp = React.createRef();
        this.searchBox = React.createRef();
    }

    componentDidMount() {
        console.log('initOverlay 1');
        if (this.searchOpenBtn) {
            console.log('initOverlay 2');
            this.searchOpenBtn.addEventListener('click', this.open, false);
        }
    }

    open = () => {
        console.log('overlay click detected');
        this.searchPopUp.current.open();
        this.searchBox.current.setFocus();
    };

    render() {
        return (
            <React.Fragment>
                <PopUp ref={this.searchPopUp} className='reveal--secondary reveal--full flex align-mc' disableOuterPageScroll={true}>
                    <div className="pad-20 text-width width-100p">
                        <div className="reveal__close" onClick={() => {this.searchPopUp.current.close()}} />
                        <SearchBox ref={this.searchBox} />
                    </div>
                </PopUp>
            </React.Fragment>
        );
    }
}

export default SearchOverlay;