import React, {useState} from "react";
import InjectInsideHtmlComponent from "../../base/injectInsideHtmlComponent";
import TwoLineInputSubscription from "../../subscriptions/twoLineInput";
import ReferralContent from "../../subscriptions/partials/referralModalContent";
import {
    ParseShortcodeAttrsForTwoLineInput
} from "../../subscriptions/shortcodeUtils";

export default function NewsletterFormShortcodeModal(props) {

    const [isSuccess, setIsSuccess] = useState(false);
    const [giftName, setGiftName] = useState('gift');
    const [referralUrl, setReferralUrl] = useState('');

    const openSuccess = (response) => {
        setIsSuccess(true);
        setGiftName(response['gift_name']);
        setReferralUrl(response['open_url']);
    };

    const onClickReferral = (e) => {
        window.open(referralUrl, '_blank').focus();
        props.close();
    };

    if (isSuccess) {
        return <React.Fragment>
            <style>
                {`.reveal .close-button, .reveal .close-circle:not(.close-referral) {display: none;}`}
            </style>
            <ReferralContent onClick={onClickReferral} giftName={giftName} close={props.close} />
        </React.Fragment>
    }

    const parsedAttrs = ParseShortcodeAttrsForTwoLineInput(props.html, {...props.formAttrs});
    const regex = /\[TwoLinesForm.[^\]]*\]/i;
    return <InjectInsideHtmlComponent regex={regex} passedComponent={TwoLineInputSubscription} html={props.html}
                                        passedAttrs={{...parsedAttrs, close: props.close, onReferralModalOpen: openSuccess}} />

}