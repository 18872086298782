import React from "react";
import LazyLoad from 'react-lazyload';
import List from './list';

class Grid extends React.Component {

    render() {
        return (
            <LazyLoad>
                <List {...this.props} />
            </LazyLoad>
        )
    }

}
export default Grid;