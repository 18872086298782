import React, {PureComponent} from "react";
import ArticleCard from "../article/card";
import PropTypes from "prop-types";
import AdBanner from '../adBanners/ad';

class List extends PureComponent {

    // state = {
    //     collection: this.props.collection,
    //     spots: this.props.spots
    // };
    //
    // shouldComponentUpdate(nextProps){
    //     return false;
    // }

    randomSpots = (length) => {
        let adSpot1 = null, adSpot2 =null;
        if (length > 4)
            adSpot1 = Math.floor(Math.random() * 8) + 4;
        if (length > 16)
            adSpot2 = Math.floor(Math.random() * 8) + 16;

        if (adSpot1 > length && length > 4 && length < 11) {
            adSpot1 = length;
        } else {
            if (adSpot2 > length && length > 15 && length < 23)
                adSpot2 = length;
        }
        return [adSpot1, adSpot2]
    };

    renderList = () => {
        let length = this.props.collection.length;
        let result = [];
        let curArticle =0, curSpot = 0;
        let [adSpot1, adSpot2] = this.props.spots || this.randomSpots(length);

        while (curArticle < length || curSpot === adSpot1 || curSpot === adSpot2) {
            if (curSpot === adSpot1 || curSpot === adSpot2){
                let randKey = `ad_${curSpot}`;
                result.push(<div className="large-3 medium-6 small-12 cell" key={randKey} >
                    <div className='card card__banner'><AdBanner /></div>
                </div>);
            } else {
                let article = this.props.collection[curArticle];
                result.push(<div className={!!this.props.itemColumnsClass ? this.props.itemColumnsClass : "large-3 medium-6 small-12 cell"} key={`article${article.id}`} >
                    <ArticleCard article={article} careerPath={this.props.careerPath} withSeo={this.props.withSeo} useLargeImage={this.props.useLargeImage} />
                </div>);
                curArticle++;
            }
            if (curSpot%12 === 11 && curArticle !== length){
                result.push(
                    <div className={"small-12 cell mv_slot_target--container"} key={`mv_slot_${curSpot}`}>
                        <div className="mv_slot_target" data-slot="feed" data-hint-slot-sizes="d728x90,d320x50,d300x50,d320x100,d468x60,d970x250,d970x90"/>
                    </div>
                    )

            }

            curSpot++;
        }
        return result;
    };

    render() {
        return this.renderList();
    }
}
List.contextTypes = {
    store: PropTypes.object
};
export default List;