import React, {Component} from "react";

class ListTop extends Component {


    render() {
        return(
              <div className='grid-x edit-results align-middle' style={{marginBottom: '20px'}}>
                  <div className="medium-4 small-12 cell">
                      <h3 className="articles-total">{this.props.count ? `${this.props.count} ${this.props.header}${this.props.count > 1 ? 's' : ''} ${this.props.headerPostfix ? this.props.headerPostfix: '' }` : null}</h3>
                  </div>
                  <div className="medium-8 small-12 cell">
                      {this.props.children}
                  </div>
              </div>
        );
    }

}

export default ListTop;