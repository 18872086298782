export default {
   development: {
       site_key: '6Lf5wNckAAAAAHlajoHobqQAraYRs9i_dX3J28iY'
   },
    staging: {
        site_key: '6LeoOSkaAAAAAMibOldEeGeIyOJ_PXfAdVn8d1Dp'
    },
    production: {
       site_key: '6LfxwXspAAAAAGefGUACQGpcVqTtdBQyo49Mps2_'
    }
}[process.env.RAILS_ENV]