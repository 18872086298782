import React, { Component } from 'react';
import {render as reactDomRender} from "react-dom";

export default class InjectInsideHtmlComponent extends Component {

    componentDidMount(){
        const uniqueId = `icc${Math.random().toString(36).substring(7)}`;
        const PassedComponent = this.props.passedComponent;
        this.dynamicContentWrapper.innerHTML = this.props.html.replace(this.props.regex, `<div id="${uniqueId}"></div>`);
        reactDomRender(<PassedComponent {...this.props.passedAttrs} />, this.dynamicContentWrapper.querySelector(`#${uniqueId}`));
    }

    render() {
        return (
            <div ref={el => (this.dynamicContentWrapper = el)} />
        );
    }


}